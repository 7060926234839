import React, { Component } from 'react';
import axios from 'axios';
import './App.css';

class BonusTalepForm extends Component {
  state = {
    bonusKategorisi: '',
    bonuslar: [],
    secilenBonus: '',
    talepGonderiliyor: false,
    loading: false,
    talepStatus: '',
    kullaniciAdi: '',
    bonusGeçmişi: [], // Bonus geçmişini saklamak için
    error: false,
  };

  getBonusName = (bonusId) => {
    const bonusNames = {
      freebet: '100 Deneme Freebet',
      freespin: '100 Deneme Freespin',
      1005: '%150 Hoş Geldin - Casino',
      1006: '%150 Hoş Geldin - Spor',
      1003: '%50 Slot Bonusu',
      1011: '%30 Freebet Bonusu',
      1009: '%20 Slot Bonusu',
      1001: '%15 Çevrimsiz Spor Bonusu',
      1002: '%15 Canlı Casino Bonusu',
      1100: '%30\'a Varan Kayıp Bonusu',
      1110: '%10 Haftalık Spor Bonusu',
      1111: '%10 Haftalık Casino Bonusu',
      2001: '75 Ertesi Gün Freespin',
      2002: '35 Hafta Sonu Freespin',
      2003: '25 Happy Hours Freespin(2₺)',
      2004: '50 Happy Hours Freespin(3₺)',
      2005: '100 Happy Hours Freespin(5₺)',
      // Diğer bonuslar ve türleri...
    };

    return bonusNames[bonusId] || 'Bilinmeyen Bonus Türü';
  };

  clearNotification = () => {
    setTimeout(() => {
      this.setState({ talepStatus: '' });
    }, 5000); // 5000 ms = 5 saniye
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const usernameFromUrl = urlParams.get('username');
    this.setState({ kullaniciAdi: usernameFromUrl || '' }, this.fetchBonusGeçmişi);

    // Zamanlayıcıyı başlat
    this.interval = setInterval(this.fetchBonusGeçmişi, 10000); // 10 saniyede bir güncelle
  }

  componentWillUnmount() {
    // Bileşen kaldırılırken zamanlayıcıyı temizle
    clearInterval(this.interval);
  }

  fetchBonusGeçmişi = () => {
    const { kullaniciAdi } = this.state;
    if (kullaniciAdi) {
      axios
        .get(`https://api.antikbet.dev:1881/request/userThreeRequest?username=${kullaniciAdi}`)
        .then((response) => {
          this.setState({ bonusGeçmişi: response.data });
        })
        .catch((error) => {
          console.error('Bonus geçmişi yüklenirken bir hata oluştu:', error);
        });
    }
  };

  handleBonusKategorisiChange = (event) => {
    const selectedCategory = event.target.value;
    const bonuslar = this.getBonuslarByCategory(selectedCategory);
    this.setState({
      bonusKategorisi: selectedCategory,
      bonuslar,
      secilenBonus: '',
      talepStatus: '',
    });
  }

  handleSecilenBonusChange = (event) => {
    this.setState({ secilenBonus: event.target.value });
  }

  handleKullaniciAdiChange = (event) => {
    this.setState({ kullaniciAdi: event.target.value });
  }

  handleTalepGonder = () => {
    const { kullaniciAdi, secilenBonus } = this.state;
    
    if (!kullaniciAdi || !secilenBonus) {
      this.setState({ talepStatus: 'Lütfen tüm alanları doldurun' }, this.clearNotification);
      return;
    }
  
    const bonusType = this.getBonusTypeByBonusName(secilenBonus);
    const requestURL = `https://api.antikbet.dev:1881/request/add?username=${kullaniciAdi}&bonustype=${bonusType}`;
  
    this.setState({ talepGonderiliyor: true, loading: true });
  
    axios
      .post(requestURL)
      .then((response) => {
        this.setState({ loading: false });
        const message = response.data.message;
        console.log('Gelen mesaj:', message); // Yanıt mesajını kontrol edin
        this.setState({ talepStatus: message }, this.clearNotification);
        
        // Bonus geçmişini güncelle
        this.fetchBonusGeçmişi();
      })
      .catch((error) => {
        this.setState({ loading: false, talepStatus: 'Talep gönderme başarısız oldu' }, this.clearNotification);
        console.error('Hata:', error); // Hata durumunu kontrol edin
      })
      .finally(() => {
        this.setState({ talepGonderiliyor: false });
      });
  };

  // Render fonksiyonu örneği:
  render() {
    const { talepStatus, loading, talepGonderiliyor } = this.state;

    return (
      <div>
        {loading && <p>Yükleniyor...</p>}
        {talepGonderiliyor && <p>Talep gönderiliyor...</p>}
        {talepStatus && <p>{talepStatus}</p>}
        {/* Diğer render içeriği */}
      </div>
    );
  }

  getBonuslarByCategory(selectedCategory) {
    const bonusData = {
      denemebonusu: ['100 Deneme Freespin', `100₺ Deneme Freebet`],
      kayipbonusu: ['%30\'a Varan Anlık Kayıp Bonusu', '%10 Haftalık Kayıp Bonusu - Spor', '%10 Haftalık Kayıp Bonusu - Casino'],
      yatirimbonusu: [`%150 Hoş Geldin - Spor Bonusu`, '%150 Hoş Geldin - Casino Bonusu', `%50 Slot Bonusu`, `%30 Freebet Bonusu`, `%20 Slot Bonusu + %10 Kayıp Bonusu`, `%15 Çevrimsiz Spor + %10 Kayıp Bonusu`, `%15 Canlı Casino Bonusu`],
      /*
      '75 Ertesi Gün Freespin': '2001',
      '35 Hafta Sonu Freespin': '2002',
      '25 Happy Hours Freespin(2₺)': '2003',
      '50 Happy Hours Freespin(3₺)': '2004',
      '100 Happy Hours Freespin(5₺)': '2005',
      */
      ozelbonuslar: [`75 Ertesi Gün Freespin`, `35 Hafta Sonu Freespin`, `25 Happy Hours Freespin(2₺)`, `50 Happy Hours Freespin(3₺)`, `100 Happy Hours Freespin(5₺)`],
      // Diğer kategoriler ve bonuslar...
    };
    return bonusData[selectedCategory] || [];
  }

  getNotificationStyle = (status) => {
    const styles = {
      'Lütfen tüm alanları doldurun': { backgroundColor: '#FF5733', color: '#fff' },
      'Bekleyen talebiniz mevcuttur.': { backgroundColor: '#4682b4', color: '#fff' },
      'Talep oluşturulurken bir hata oluştu.': { backgroundColor: '#FF5733', color: '#fff' },
      'Talebiniz başarılı bir şekilde oluşturulmuştur.': { backgroundColor: '#3cb371', color: '#white' }
    };
  
    return styles[status] || {};
  };
  
  getNotificationMessage = (status) => {
    const messages = {
      'Lütfen tüm alanları doldurun': 'Lütfen tüm alanları doldurunuz.',
      'Bekleyen talebiniz mevcuttur.': 'Bekleyen talebiniz mevcuttur.',
      'Talep oluşturulurken bir hata oluştu.': 'Talep oluşturulurken bir hata oluştu.',
      'Talebiniz başarılı bir şekilde oluşturulmuştur.': 'Talep başarılı bir şekilde oluşturulmuştur.'
    };
  
    return messages[status] || '';
  };

  getBonusTypeByBonusName(bonusName) {
    const bonusTypes = {
      '100 Deneme Freespin': 'freespin',
      '100₺ Deneme Freebet': 'freebet',
      '%30\'a Varan Anlık Kayıp Bonusu': '1100',
      '%150 Hoş Geldin - Casino Bonusu': '1005',
      '%150 Hoş Geldin - Spor Bonusu': '1006',
      '%50 Slot Bonusu': '1003',
      '%30 Freebet Bonusu': '1011',
      '%20 Slot Bonusu + %10 Kayıp Bonusu': '1009',
      '%15 Çevrimsiz Spor + %10 Kayıp Bonusu': '1001',
      '%15 Canlı Casino Bonusu': '1002',
      '%10 Haftalık Kayıp Bonusu - Spor': '1110',
      '%10 Haftalık Kayıp Bonusu - Casino': '1111',
      '75 Ertesi Gün Freespin': '2001',
      '35 Hafta Sonu Freespin': '2002',
      '25 Happy Hours Freespin(2₺)': '2003',
      '50 Happy Hours Freespin(3₺)': '2004',
      '100 Happy Hours Freespin(5₺)': '2005',
      // Diğer bonuslar ve türleri...
    };
    return bonusTypes[bonusName] || '';
  }

  toggleBonusDetails = () => {
    this.setState((prevState) => ({ showBonusDetails: !prevState.showBonusDetails }));
  };


  render() {
    const { talepStatus, loading, talepGonderiliyor, bonusGeçmişi, selectedBonus } = this.state;

    return (
      <div className="container">
        <div className="form">
          <h2>AntikBet - Bonus Talep Formu</h2>
          <div className="loading-bar-container">
          {this.state.loading && <div className="loading-bar" />}
        </div>
        {this.state.loading ? (
          <h5 style={{ color: '#f27127' }}>Bonusunuz kontrol ediliyor...</h5>
        ) : (
          <h5 style={{ color: 'grey' }}>{`Hoş geldiniz ${this.state.kullaniciAdi}`}, aşağıdan bonusunuzu seçiniz:</h5>
        )}
        {talepStatus && (
          <div className="notification" style={this.getNotificationStyle(talepStatus)}>
            {this.getNotificationMessage(talepStatus)}
          </div>
        )}
          <div className="form-group">
            <label htmlFor="bonusKategorisi" className="label">Bonus Kategorisi:</label>
            <select
              id="bonusKategorisi"
              className="input"
              value={this.state.bonusKategorisi}
              onChange={this.handleBonusKategorisiChange}
            >
              <option value="">Seçiniz</option>
              <option value="denemebonusu">Deneme Bonusları</option>
              <option value="yatirimbonusu">Yatırım Bonusları</option>
              <option value="kayipbonusu">Kayıp Bonusu</option>
              <option value="ozelbonuslar">Özel Bonuslar</option>
              {/* Diğer kategoriler */}
            </select>
          </div>
          {this.state.bonuslar.length > 0 && (
            <div className="form-group">
              <label htmlFor="secilenBonus" className="label">Bonus Seç:</label>
              <select
                id="secilenBonus"
                className="input"
                value={this.state.secilenBonus}
                onChange={this.handleSecilenBonusChange}
              >
                <option value="">Seçiniz</option>
                {this.state.bonuslar.map((bonus, index) => (
                  <option key={index} value={bonus}>
                    {bonus}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="form-group">
            {this.state.talepGonderiliyor ? (
              <button className="button" disabled>Gönderiliyor...</button>
            ) : (
              <div>
                <button className="button" onClick={this.handleTalepGonder}>Talebi Gönder</button>
              </div>
            )}
            <div className="bonus-talepleri-form">
              <hr className="separator" />
              <div className="bonus-gecmisi">
                <h3>Bonus Geçmişi</h3>
                {bonusGeçmişi.length === 0 ? (
                  <p>Bonus geçmişiniz bulunmamaktadır.</p>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th>Bonus Türü</th>
                        <th>Durum</th>
                        <th>Talep Tarihi</th>
                        <th>Red Nedeni</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bonusGeçmişi.map((bonus, index) => (
                        <tr key={index}>
                          <td>{this.getBonusName(bonus.bonusType)}</td>
                          <td>
                            <span className={`status-badge ${bonus.status}`}>
                              {bonus.status === 'rejected' ? 'Rededildi' : bonus.status === 'approved' ? 'Onaylandı' : 'Beklemede'}
                            </span>
                          </td>
                          <td>{new Date(bonus.createdAt).toLocaleString()}</td>
                          <td>
                            {bonus.status === 'rejected' && (
                              <button
                                className="show-reason-btn"
                                onClick={() => this.setState({ selectedBonus: bonus })}
                              >
                                Göster
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {this.state.selectedBonus && (
                  <div className="modal-overlay">
                    <div className="modal-content">
                      <h4>Red Nedeni</h4>
                      <p>{this.state.selectedBonus.rejectReason || 'N/A'}</p>
                      <button onClick={() => this.setState({ selectedBonus: null })}>Kapat</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BonusTalepForm;